import React from 'react'
import { useRef } from "react";
import { categoriesFromConfig, getHomeUrl, subCategoriesFromConfig, toKebabCase } from "../configuration/ApiConfig";
import { FaTimes } from "react-icons/fa";
const Navbar = ({ handleClick, showNavbar }) => {
    const navRef = useRef();
    const getSub = (cat) => {
        if(subCategoriesFromConfig[cat][0]){
            return subCategoriesFromConfig[cat][0];
        } else {
            return 'items';
        }
    }
    return (
        <div className={`navbar-wrapper `}>
            <nav className={`navbar`}>
                <div className={`nav-list`} ref={navRef}>
                    {categoriesFromConfig.map((cat, index) => {
                        return (
                            <a href={`${getHomeUrl()}/products/${toKebabCase(cat)}/${toKebabCase(getSub(cat))}`}>
                                {cat}
                                </a>
                        )
                    })}
                    <button className={`nav-btn nav-close-btn`}>
                        <FaTimes onClick={showNavbar} />
                    </button>
                </div>
            </nav>
        </div>
    )
}

export default Navbar